/***** Always True *****/
@media only screen {
    /*** General Purpose Display Styles ***/
    .none {display: none;}
    .flex {display: flex;}
    .column {flex-direction: column;}
    .absolute {position: absolute !important;}
    .hidden {visibility: hidden;}
    .row-reverse {flex-direction: row-reverse;}
    .column-reverse {flex-direction: column-reverse;}
    .align-center {align-items: center;}
    .justify-center {justify-content: center;}
    .align-self-center {align-self: center;}
    .align-self-start {align-self: flex-start;}
    .align-self-end {align-self: flex-end;}
    .justify-around {justify-content: space-around;}
    .scroll {overflow: scroll;}
    .no-scrollbar::-webkit-scrollbar {display: none;}
    .no-scrollbar {-ms-overflow-style: none;scrollbar-width: none;}
    .pointer:hover {cursor: pointer;}
    .text-center {text-align: center;}
    .justify-between {justify-content: space-between;}
    .padding-0 {padding: 0 !important;}
    .justify-end {justify-content: flex-end;}

    /*** Widths ***/
    .width-60 {width: 60%;}
    .width-80 {width: 80%;}
    .width-100 {width: 100%;}

    /*** Heights ***/
    .height-95 {height: 95vh;}
    .height-100 {height: 100%;}
    
    /*** Shapes ***/
    .circle {border-radius: 50%;}
    
    /*** Paddings ***/
    .padding-30px {padding: 30px;}

    /*** Margins ***/
    .margin-0 {margin: 0;}
    .margin-30px {margin: 30px;}
    .margin-40px {margin: 40px;}

    /*** Colors ***/
    .black {color: #000;}
    .white {color: #fff;}

    /*** Opacities ***/

    /*** Fonts ***/

    /*** Text Styles ***/

    /*** Background Color Gradients ***/
    .red    {background: linear-gradient(225deg, #F78888 0%, #DA6767 88.84%);}
    .blue   {background: linear-gradient(225deg, #91FFFF 0%, #87E9FF 88.84%);}
    .green  {background: linear-gradient(180deg, #39CBB9 0%, #5BFE9E 100%);}
    .purple {background: linear-gradient(269.15deg, #C183F2 4.11%, #E5B2FF 96.97%);}
    .orange {background: linear-gradient(225deg, #D69334 0%, #FD8F3C 88.84%);}
    .yellow {background: linear-gradient(225deg, #FBFF3C 0%, #DCD531 88.84%)}
    .cardboard {background-image: url("../media/images/cardboard.png"); background-size: cover;}

    /*** Shadows ***/
    .box-shadow {box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);}
    .box-shadow-75 {box-shadow: 0px 4px 75px 20px rgba(0, 0, 0, 0.2);}
    .box-shadow-40 {box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.04), inset 0px 0px 72px 10px rgba(0, 0, 0, 0.04);}

    /*** Rotations ***/
    .rotate-45 {transform: rotate(45deg);}
    
    /*** Squares ***/

    /*** Element Styles ***/

    /*** Class Styles ***/
    .pointer:hover {cursor: pointer; opacity: 50%;}
    .darken:hover {opacity: 100%;}
    .typing {
      overflow: hidden;
      animation: 
          typing 3.5s,
          blink-caret .75s step-end infinite;
    }
    .appear-animation {
      animation: appear 5s forwards;
    }
    .slow-appear-animation {
      animation: appear 7s;
    }
    .hide-animation {animation: shuffle 2s linear forwards;}
    .profile-text {margin-bottom: 10vw;}

    /*** Animations ***/

    @keyframes typing {
        from { width: 0 }
        to { width: 100% }
      }
      
      /* The typewriter cursor effect */
      @keyframes blink-caret {
        from, to { border-color: transparent }
        50% { border-color: #000; }
      }

      @keyframes appear {
          0% {opacity: 0;}
          100% {opacity: 100%;}
      }

      @keyframes shuffle {
        0% {transform: translateY(-100%);}
        50% {transform: translateY(-220%); opacity: 100%;}
        100% {transform: translateY(-100%); opacity: 0%; z-index: -1;}
      }
}

/***** > 700px *****/
@media only screen and (min-width: 700px) {
}

/***** <= 700px *****/
@media only screen and (max-width: 700px) {
}